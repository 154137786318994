import { Link } from "gatsby";
import React from "react";
import "../../markup/assets/css/bootstrap/bootstrap-grid.min.css";
import "../../markup/assets/css/icon/style.css";
import "../../markup/assets/css/style.min.css";
import Layout from "../components/layout";
import SEO from "../components/seo";

const TermsOfServices = () => (
  <Layout>
    <SEO title="Terms of Services" />
    <div>
      <div className="pt200 pb200 pb-md-100 background__whiteyTightee section--relative terms-section">
        <div className="shape__layer">
          <img
            src="https://assets.wpinsight.com/terms-wave.png"
            className="cta-wave"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/dot.png"
            className="dot"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="wpi-terms__contidion">
                <h1>Terms and Condition</h1>
                <div className="terms__block mb50">
                  <h4>1. Your relationship with WPinsight</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="terms__block mb50">
                      <h4>2. Your relationship with WPinsight</h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                        Richard McClintock, a Latin professor at Hampden-Sydney
                        College in Virginia, looked up one of the more obscure
                        Latin words.
                      </p>
                    </div>
                    <div className="terms__block">
                      <h4>3. Your relationship with WPinsight</h4>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                        Richard McClintock, a Latin professor at Hampden-Sydney
                        College in Virginia, looked up one of the more obscure
                        Latin words.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="terms__thumb pt-md-50">
                      <img
                        src="https://assets.wpinsight.com/thumb-1.png"
                        alt="Terms thumb"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Terms End */}
      {/* Call to Action */}
      <div className="pt70 pb110 pt-md-0 background__whiteyTightee section--relative cta-section">
        <div className="shape__layer">
          <img
            src="https://assets.wpinsight.com/cta-bottom-shape.png"
            className="bottom-bg-shape"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/dot.png"
            className="dot"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="wpi-call-to-action background__white">
                <div className="call-to-action-content">
                  <h2>
                    100% Risk Free 14 <br /> Days Money Back Guarantee!
                  </h2>
                </div>
                <div className="button-group">
                  <a href="#" className="button secondary__button">
                    Get Started
                  </a>
                  <a
                    href="#"
                    className="button primary__button hover__highlight"
                  >
                    Check live demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default TermsOfServices;
